import React from 'react';
import styled from 'styled-components';
import laptopImage from '../img/backgrounds/bg_laptop.webp';
import float1Image from '../img/backgrounds/bg_float_1.webp';
import float2Image from '../img/backgrounds/bg_float_4.webp';
import float3Image from '../img/backgrounds/bg_float_5.webp';
import float4Image from '../img/backgrounds/bg_float_3.webp';
import float5Image from '../img/backgrounds/bg_float_2.webp';

const Root = styled.div`
    flex: 0 0 auto;
    min-width: 550px;
    height: 100%;
    max-height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const BgElementsContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const BgElement = styled.div<{$animationDelayMs?: number}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: ${({$animationDelayMs}) => $animationDelayMs === undefined ? '1' : '0'};
    @keyframes fade {
        0% {opacity: 0;} 100% {opacity: 0.9;}
    }
    animation: ${({$animationDelayMs}) => $animationDelayMs === undefined ? 'none' : `fade 1s linear ${$animationDelayMs}ms forwards`};
`;
const BgElement1 = styled(BgElement)`
    background-image: url("${laptopImage}");
`;
const BgElement2 = styled(BgElement)`
    background-image: url("${float1Image}");
`;
const BgElement3 = styled(BgElement)`
    background-image: url("${float2Image}");
`;
const BgElement4 = styled(BgElement)`
    background-image: url("${float3Image}");
`;
const BgElement5 = styled(BgElement)`
    background-image: url("${float4Image}");
`;
const BgElement6 = styled(BgElement)`
    background-image: url("${float5Image}");
`;

const Text = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
`;
const TextBig = styled.div`
    font-size: 34px;
    font-weight: bold;
    line-height: 1.2;
`;
const TextColored = styled.span`
    color: #284D67;
`;
const TextSmall = styled.div`
    margin-top: 15px;
    font-size: 18px;
    line-height: 1.4;
`;

const BackgroundImageAndText = () => (
  <Root>
    <BgElementsContainer>
      <BgElement1/>
      <BgElement2 $animationDelayMs={1000}/>
      <BgElement3 $animationDelayMs={2000}/>
      <BgElement4 $animationDelayMs={3000}/>
      <BgElement5 $animationDelayMs={4000}/>
      <BgElement6 $animationDelayMs={5000}/>
    </BgElementsContainer>

    <Text>
      <TextBig>Work faster. <TextColored>Collaborate smarter.</TextColored><br/>Stay in control.</TextBig>
      <TextSmall>Help your business work smarter and grow faster.<br/>www.cwaysoftware.com</TextSmall>
    </Text>
  </Root>
);

export default BackgroundImageAndText;

