import { useLayoutEffect, useState } from 'react';

export const useViewportSize = () => {
  const [size, setSize] = useState({width: 0, height: 0});

  useLayoutEffect(() => {
    const updateSize = () => setSize({width: window.innerWidth, height: window.innerHeight});
    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
